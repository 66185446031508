import React from 'react';
import SuperHero from "../assets/superhero.png";
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import Cover from "../assets/cover_bg.png";
import {motion} from "framer-motion";
const Landing = () => {
    return (<> 
    <div className="container">
    <motion.div 
    initial={{opacity:0,y:100}}
    animate={{opacity:1,y:0}}
    transition={{duration:0.5}}
     className="left_container">
        <div className="center_container">
        <div className="heading_website">
            <h1 className="website_header">WEBSITE</h1>
        </div>
        <motion.h1  className="coming_text">COMING</motion.h1>
        <h1 className="soon_text">SOON</h1>
        <div className="form_container">
        <form style={{position:"relative", height:"6.23342175066313vh", width:"100%"}} className="form" action="https://formspree.io/f/mknppbvw" method="POST">
            <motion.input 
            style={{height:"100%"}}
            whileHover={{
            scale:1.1
        }}  className="input"  type="email" name="_replyto" placeholder="Enter your email..." ></motion.input>
            <motion.input   animate={{y:"-0.3vh"}} transition={{duration:0.1}} type="submit" value="erinnere mich" className="notify_me" style={{    
            cursor:"pointer",
            background: "#3E3737",
            outline:"none",
            marginLeft:"5%",
            zIndex:999,
            position:"relative",
            color:"white",
            width:"25%",
            height:"100%",
            border:"none",
            }}/>
        </form>
       
        </div>
        <div className="lower_website">
        <h1 className="your_text">DEIN REGIONALER </h1>
        <h1 className="superhero_text">Super
        <motion.span 
        animate={{color:"#EC1F27" }} 
        transition={{duration:1}}
        >held</motion.span></h1>
        </div>
       
        </div>
       
    </motion.div>
    <motion.div   initial={{opacity:0,y:100}}
    animate={{opacity:1,y:0}}
    transition={{duration:0.5}} className="right_container">
      <motion.img 
      initial={{y:100}}
      animate={{y:0}}
      transition={{duration:1}}
      className="super_hero_images" src={SuperHero}/>
      <img className="cover"  src={Cover} alt=""/>  
    <span className="links">
            <motion.a whileHover={{scale:1.5}} target = '_blank' href=" https://www.facebook.com/RegioHeld.at/"><FacebookIcon fontSize="large"/></motion.a>
            <motion.a whileHover={{scale:1.5}} target = '_blank' href="https://www.instagram.com/regioheld.at/"><InstagramIcon fontSize="large"/></motion.a>
    </span>
    </motion.div>
  
    </div>
       </> );
}
     
export default Landing;